




import LoginForm from '@/components/LoginForm.vue'; // @ is an alias to /src
import Vue from 'vue';
export default Vue.extend({
  components: {
    LoginForm
  }
});
