<template>
  <v-container fluid fill-height style="margin-top:100px;">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-form ref="form" v-model="valid" @submit.prevent="handleLogin">
          <v-card class="elevation-12">
            <v-card-text>
              <v-alert :value="invalidLogin" color="error" icon="warning" outline class="mb-3">
                The username or password you entered is incorrect.
                <br />Verify these informations and try again.
              </v-alert>
              <label
                aria-hidden="true"
                class="v-label v-label--active theme--light"
                style="left: 50px; right: auto; position: absolute;font-size:12px;"
                >Username</label
              >
              <v-text-field
                prepend-icon="person"
                name="login"
                type="text"
                v-model="user"
                :error="invalidLogin"
                :rules="loginNameRules"
              />
              <label
                aria-hidden="true"
                class="v-label v-label--active theme--light"
                style="left: 50px; right: auto; position: absolute;font-size:12px;"
                >Password</label
              >
              <v-text-field
                id="password"
                prepend-icon="lock"
                name="password"
                type="password"
                v-model="pwd"
                :error="invalidLogin"
                :rules="loginPwdRules"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-progress-circular v-if="loginProgress" indeterminate />
              <v-btn v-else type="submit" color="primary" @click="handleLogin()">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data: () => ({
    loginProgress: false,
    valid: true,
    invalidLogin: false,
    user: '',
    pwd: '',
    loginPwdRules: [(v) => !!v || 'Username is required'],
    loginNameRules: [(v) => !!v || 'Password is required']
  }),
  props: {
    source: String
  },
  async mounted() {
    //auto login
    await this.auth();
    if (this.isAuthed) {
      this.$router.push({ name: 'Home' });
    }
  },
  computed: {
    ...mapState({
      isAuthed: (state) => state.userProfile.isAuthed
    })
  },
  methods: {
    ...mapActions({
      auth: 'userProfile/auth'
    }),
    async handleLogin() {
      this.loginProgress = true;
      if (this.$refs.form.validate()) {
        await this.auth({ user: this.user.trim(), pass: this.pwd.trim() });
        if (this.isAuthed) {
          this.$router.push({ name: 'Home' });
        } else {
          this.$refs.form.validate();
          this.invalidLogin = true;
        }
      }
      this.loginProgress = false;
    }
  }
};
</script>
